import {AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Observable, map, of, startWith } from 'rxjs';
import { ComplexityModalComponent } from '../complexity-modal/complexity-modal.component';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-edit-fire',
  templateUrl: './edit-fire.component.html',
  styleUrl: './edit-fire.component.scss'
})
export class EditFireComponent implements OnInit, AfterViewInit {

  private readonly isBrowser: boolean;

  currentUser: any;
  private fireRecord: any = this.data.fireRecord;
  mergeList: any;
  mergeListLength = "-";
  est_nfs_acres_total = 0;
  total_fire_acres_total = 0;
  displayedColumns!: string[];
  selectedForestFlag = false;

  regions: any;
  forest_names: any;
  filtered_forest_names: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;
  orgCodes: any;
  status_list: any;
  complexities: any;

  fireForm = this.formBuilder.group({
    fire_complex_name: [""],
    region: [""],
    forest_name: [""],
    forestOrgCode: [""],
    est_nfs_acres: [{value:"",disabled:true}],
    containment_date: [null] as unknown as Date[],
    est_containment_date: [null] as unknown as Date[],
    status: [""],
    complexity: [-1],
  });

  constructor(
    public dialogRef: MatDialogRef<EditFireComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private toast: ConfirmationToastComponent,
    private assessmentService: AssessmentService,
    private titleService: Title,
    private userService: UserService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.titleService.setTitle("BAER - Edit Fire")
    dialogRef.disableClose = true;
    this.regions = this.assessmentService.getRegionList();
    this.forest_names = this.assessmentService.getForestNameList();
    this.orgCodes = this.assessmentService.getOrgCodeList();
    this.status_list = this.assessmentService.getStatusList();
    this.complexities = this.assessmentService.getComplexityList();

    this.filtered_forest_names = this.fireForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )

    this.userService.getUserProfile().then((user) => {
      this.currentUser = user;
      //look for 16-WO Coordination  // 17-WO Logistics
      const isWO = this.currentUser.baer_roles.find((obj: any) => [16,17].includes(obj.value))
      if(isWO || this.data.fireRecord.source != 'MERGE') this.fireForm.get('est_nfs_acres')?.enable();
    })
  }

  ngOnInit(): void {
    this.fireForm.patchValue({
      fire_complex_name: this.fireRecord.baer_name,
      region: this.fireRecord.region,
      forest_name: this.fireRecord.forest_name,
      forestOrgCode: this.fireRecord.forest_org_code,
      est_nfs_acres: this.fireRecord.acres_fsonly,
      containment_date: this.fireRecord.containment_date? new Date(this.fireRecord.containment_date):undefined,
      est_containment_date: this.fireRecord.containment_date_est? new Date(this.fireRecord.containment_date_est):undefined,
      status: this.fireRecord.assessment_status_id,
      complexity: this.fireRecord.fire_complexity_id
    })

    if (this.fireRecord.source == "MERGE") this.getMergeList();
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forest_names.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }
  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      import('inputmask').then((Inputmask: any) => {
        let nfsAcres = document.getElementById("nfsAcres") ?? '';
        let im = new Inputmask("decimal", {
          groupSeparator: ',',
          allowMinus: false
        });
        im.mask(nfsAcres);
      })
    }
  }

  editFire(): void {
    let containment_date: Date = this.fireForm.value.containment_date as unknown as Date
    let containment_date_est: Date = this.fireForm.value.est_containment_date as unknown as Date
    let containment_date_string = undefined;
    let containment_date_est_string = undefined;
    if (containment_date) {
      containment_date_string = containment_date.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
    }
    if (containment_date_est) {
      containment_date_est_string = containment_date_est.toLocaleDateString("en-US", {year:"numeric",month:"2-digit",day:"2-digit"})
    }

    this.assessmentService.editAssessment({
      assessment_id: this.fireRecord.assessment_id,
      baer_name: this.fireForm.value.fire_complex_name!,
      region: this.fireForm.value.region!,
      forest_name: this.fireForm.value.forest_name!,
      forest_org_code: this.fireForm.value.forestOrgCode!,
      acres_fsonly: Math.round(+this.fireForm.value.est_nfs_acres?.toString().replaceAll(',','')!),
      containment_date: containment_date_string,
      containment_date_est: containment_date_est_string,
      assessment_status_id: this.fireForm.value.status as number | undefined,
      fire_complexity_id: this.fireForm.value.complexity as number | undefined
    }).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`${this.fireForm.value.fire_complex_name} record has been updated.`);
        this.dialogRef.close(true);
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error updating ${this.fireRecord.fire_complex_name}.`, "Please try editing the fire record again.");
        this.dialogRef.close();
      },
    })
  }

  getMergeList() {

    this.displayedColumns = [
      'BAER_name',
      'forestOrgCode',
      'containment_date',
      'est_nfs_acres',
      'fire_acres',
    ]

    this.assessmentService.getAssessmentMergeList(this.fireRecord.assessment_id).subscribe(data => {
      this.mergeList = data.assessments;
      this.mergeListLength = data.assessments.length || 0;

      this.mergeList.forEach((fire: any, index: number) => {
        this.est_nfs_acres_total += fire.acres_fsonly || 0;
        this.total_fire_acres_total += fire.poly_acres || 0;
      });
    });
  }

  regionChanged(): void {
    this.forest_names = this.assessmentService.getForestNameList(this.fireForm.value.region ?? undefined);
    this.filtered_forest_names = this.fireForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
    this.orgCodes = this.assessmentService.getOrgCodeList(this.fireForm.value.region ?? undefined);
    if (!this.forest_names.includes(this.fireForm.value.forest_name)) {
      this.fireForm.patchValue({
        forest_name: ""
      })
    }
  }

  forestNameSelected(event: any): void {
    let orgCode = this.assessmentService.getOrgCodeFromForest(event.option.value)
    this.fireForm.patchValue({
      forestOrgCode: orgCode,
      region: orgCode?.substring(0,2),
    })
    this.selectedForestFlag = true
  }

  orgCodeSelected(event: any): void {
    this.fireForm.patchValue({
      forest_name: this.assessmentService.getForestFromOrgCode(event.value),
      region: event.value.substring(0,2),
    })
  }

  complexityModal(event: any): void {
    event.stopPropagation();
    this.dialog.open(ComplexityModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }

  clearForest() {
    if (!this.selectedForestFlag){
      this.fireForm.patchValue({
        forest_name: ""
      })
    }
    this.selectedForestFlag = false
  }
}
