import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/services/dialog.service';
import { ReportService } from 'src/app/services/report.service';
import { faEdit, faUsers } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})


export class ReportListComponent implements OnInit {
  faEdit = faEdit;
  faUsers = faUsers;
  reports = new MatTableDataSource<any>();
  displayedColumns: string[] = ['filler','name', 'reportId','reportStatus','baerTeam','action'];

  @Input() assessment_id: any;
  @Input() assessment_name!: string;
  @Input() isCo_or!: boolean;
  @Input() isTeamLead!: boolean;

  constructor(
    private readonly reportService: ReportService,
    private readonly dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.dialogService.openSpinner()
    this.reportService.getReportList(this.assessment_id).subscribe(
      {
        next: (data) => {
          this.setTooltip(data);
          this.reports.data = data;
        },
        complete: () => {
          this.dialogService.closeSpinner()
        }
      });
  }
  
  setTooltip(reports: any) {
    reports.forEach((report: any) => {
      let tooltip = 'Team Lead(s)\n';
      for(let member of report.team_members.filter((member:any) => member.role_id === 1)) {
        tooltip += member.first_name + ' ' + member.last_name + '\n'
      }
      tooltip += '\nTeam Member(s)\n'
      for(let member of report.team_members.filter((member:any) => member.role_id === 2)) {
        tooltip += member.first_name + ' ' + member.last_name + '\n'
      }
      report.tooltip = tooltip;
    });
  }
}