<div id="wait_on_load" *ngIf="user">
    <header class="baer-header">
        <div class="breadcrumb"><span *ngIf="user?.baer_roles?.length > 0"><a routerLink="/roster">All Users</a> &gt; </span>{{user.first_name}} {{user.last_name}}</div>
        <div class="title_user">
            <div class="user-icon">
                <p>{{user.first_name[0]}}</p>
            </div>
            <div class="user-cell-details">
                <p id="table" class="site-page-title">{{user.first_name}} {{user.last_name}}</p>
                <mat-chip>User</mat-chip>
            </div>
        </div>
    </header>
    <div class="contact_card" *ngIf="!edit_contact()">
        <app-view-contact [(edit_contact)]="edit_contact" [user]="user" [editable]="editable"></app-view-contact>
    </div>
    <div class="contact_card" *ngIf="edit_contact()">
        <app-edit-contact [(edit_contact)]="edit_contact" [user]="user" [refreshUser]="refreshUser"></app-edit-contact>
    </div>
    <div class="assignments_card"><app-view-assignments [assignmentList]="assignmentList"></app-view-assignments></div>
    <div class="availability_card" *ngIf="!edit_availability()">
        <app-view-availability [(edit_availability)]="edit_availability" [user]="user" [editable]="selfEditable || isAdmin"></app-view-availability>
    </div>
    <div class="availability_card" *ngIf="edit_availability()">
        <app-edit-availability [(edit_availability)]="edit_availability" [user]="user" [refreshUser]="refreshUser" [editable]="selfEditable"></app-edit-availability>
    </div>
    <div class="skills_card" *ngIf="!edit_skills()">
        <app-view-skills [(edit_skills)]="edit_skills" [user]="user" [editable]="selfEditable"></app-view-skills>
    </div>
    <div class="skills_card" *ngIf="edit_skills()">
        <app-edit-skills [(edit_skills)]="edit_skills" [user]="user" [refreshUser]="refreshUser"></app-edit-skills>
    </div>
</div>