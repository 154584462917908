import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor() { }
    getReportList(assessment_id: number) {
        if(assessment_id == 1729) return of([])
        return of([
            {
                report_id: 4635675765,
                report_num: 1,
                report_status_id: 5,
                team_members: [
                    { first_name: 'first', last_name: 'person', role_id: 1 },
                    { first_name: 'second', last_name: 'person', role_id: 1 },
                    { first_name: 'third', last_name: 'person', role_id: 2 },
                    { first_name: 'forth', last_name: 'person', role_id: 2 }
                ]
            }, {
                report_id: 34564564563,
                report_num: 2,
                report_status_id: 4,
                team_members: [
                    { first_name: 'first', last_name: 'person', role_id: 1 },
                    { first_name: 'second', last_name: 'person', role_id: 2 }
                ]
            }, {
                report_id: 34564564563,
                report_num: 4,
                report_status_id: 6,
                team_members: []
            }, {
                report_id: 34564564563,
                report_num: 7,
                report_status_id: 7,
                team_members: []
            }, {
                report_id: 34564564563,
                report_num: 8,
                report_status_id: 8,
                team_members: []
            }, {
                report_id: 34564564563,
                report_num: 9,
                report_status_id: 9,
                team_members: []
            }, {
                report_id: 34564564563,
                report_num: 10,
                report_status_id: 10,
                team_members: []
            }, {
                report_id: 34564564563,
                report_num: 11,
                report_status_id: 11,
                team_members: []
            }, {
                report_id: 34564564563,
                report_num: 12,
                report_status_id: 12,
                team_members: []
            },
        ])
    }
}