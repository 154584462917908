import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { faEdit, faPlus, faUsers, faWarning } from '@fortawesome/free-solid-svg-icons';
import { MatTableDataSource } from '@angular/material/table';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Observable, map, of, startWith, BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { Title } from '@angular/platform-browser';
import { ComplexityModalComponent } from '../fire-tracker/complexity-modal/complexity-modal.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EditAssessmentComponent } from './edit-assessment/edit-assessment.component';
import { RequestSupportComponent } from './request-support/request-support.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class AssessmentsComponent implements OnInit {
  //icons
  faEdit = faEdit;
  faUsers = faUsers;
  faPlus = faPlus;
  faWarning = faWarning;

  //table variables
  pageNumber = 0;
  pageSize = 10;
  length = 0;
  sort = "updated_on"
  sortDirection = "desc"
  selectedForestFlag = false;
  assessments = new MatTableDataSource<any>();
  expandedAssessments: any;
  displayedColumns!: string[];

  //dropdown lists
  regions: any;
  statuses: any;
  complexities: any;
  team_leads: any;

  //access
  isTeamLead: boolean = false;
  isCo_or: boolean = false;
  user: any;

  //forest name filter
  forestNameList: string[];
  filtered_forest_names: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;
  forest_name = new FormControl("")
  selectedRegion: string | undefined = undefined;

  //search bar items
  filters = {
    region: null,
    forest_name: null as (string | null),
    baer_name: null,
    assessment_status_id: null,
    assessment_status_ids: [2, 4, 5],
    fire_complexity_id: null,
    team_lead: null,
  }
  filters_clean = true;

  last_updated_on: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    public dialog: MatDialog,
    private readonly assessmentService: AssessmentService,
    private readonly dialogService: DialogService,
    private readonly titleService: Title,
    private readonly userService: UserService
  ) {
    this.titleService.setTitle("BAER - Assessments")
    this.regions = this.assessmentService.getRegionList();
    this.statuses = this.assessmentService.getStatusList().filter((x: any) => [2, 4, 5, 12].includes(x.value));;
    this.complexities = this.assessmentService.getComplexityList();
    this.userService.getActiveLeadList().subscribe((team_leads: any) => { this.team_leads = team_leads.users });
    this.forestNameList = this.assessmentService.getForestNameList();
    this.filtered_forest_names = of(this.forestNameList)

    this.assessmentService.finishLoadingSubscription().subscribe(() => {
      this.regions = this.assessmentService.getRegionList();
      this.statuses = this.assessmentService.getStatusList().filter((x: any) => [2, 4, 5, 12].includes(x.value));
      this.complexities = this.assessmentService.getComplexityList();
      this.forestNameList = this.assessmentService.getForestNameList(this.filters.region as any);

      this.filtered_forest_names = of(this.forestNameList)
      this.filtered_forest_names = this.forest_name.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter((value as string) || ""))
      )
    })
  }

  regionChanged(): void {
    this.forestNameList = this.assessmentService.getForestNameList(this.filters.region as any);
    this.filtered_forest_names = this.forest_name.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  ngOnInit(): void {
    this.loadAssessments();
    this.loadUser();

    this.displayedColumns = ['collapse',
      'baer_name',
      'region',
      'assessment_status_id',
      'fire_complexity_id',
      'team_needs',
      'team_lead',
      'actions'
    ]
    this.filtered_forest_names = this.forest_name.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  loadUser() {
    this.userService.getUserProfile().then((user) => {
      this.user = user;
      // 0-Reporting Tool Administrator
      // 4-BAER National Coordinator
      // 7-Washington Office Director
      // 8- Forest BAER Coordinator
      // 12- RO BAER Coordinator
      // 16-WO Coordination
      // 17-WO Logistics
      const isCo_or = user.baer_roles.find((obj: any) => [0, 4, 7, 8, 12, 16, 17].includes(obj.value))
      if (isCo_or) this.isCo_or = true;
      else { //keep highest privilage
        // 1-BAER Team Leader
        // 5-BAER Forest Unit Coordinator
        const isTeamLead = user.baer_roles.find((obj: any) => [1, 5].includes(obj.value))
        if (isTeamLead) this.isTeamLead = true;
      }
    });
  }

  private loadAssessmentMetadata() {
    this.assessmentService.getAssessmentMetadata().subscribe(metadata => this.last_updated_on.next(metadata?.updated_on));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forestNameList.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }
  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  search(): void {
    this.filters_clean = false;
    this.pageNumber = 0;
    this.loadAssessments();
  }

  clearSearch(): void {
    this.filters_clean = true;
    this.pageNumber = 0;
    this.filters = {
      region: null,
      forest_name: null,
      baer_name: null,
      assessment_status_id: null,
      assessment_status_ids: [2, 4, 5],
      fire_complexity_id: null,
      team_lead: null,
    }
    this.forest_name.setValue("");
    this.regionChanged();
    this.loadAssessments();
  }

  pageEvent(e: any) {
    this.pageNumber = e.pageIndex;
    this.pageSize = e.pageSize;
    this.loadAssessments();
  }

  loadAssessments() {
    this.dialogService.openSpinner()
    this.filters.forest_name = this.forest_name.value ?? null;
    this.assessmentService.getAssessmentList(this.pageNumber + 1, this.pageSize, this.filters, this.sort, this.sortDirection).subscribe(
      {
        next: (data) => {
          this.assessments.data = data.assessments;
          this.length = data.totalResults;
        },
        complete: () => {
          this.dialogService.closeSpinner()
          this.loadAssessmentMetadata();
        }
      });
  }

  sortChange(sortState: any) {
    this.sort = sortState.active;
    this.sortDirection = sortState.direction;
    this.pageNumber = 0;
    this.loadAssessments()
  }

  clearValue(event: any) {
    event.target.value = ""
  }

  clearForest() {
    if (!this.selectedForestFlag) {
      this.forest_name.setValue("")
    }
    this.selectedForestFlag = false
  }

  forestSelected() {
    this.selectedForestFlag = true
  }

  complexityModal(): void {
    this.dialog.open(ComplexityModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }

  editBaerTeamNeeds(assessment: any): void {
    this.dialog.open(EditAssessmentComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: assessment,
        isTeamLead: this.isTeamLead
      },
    }).afterClosed().subscribe((reload) => {
      this.titleService.setTitle("BAER - Assessments")
      if (reload) {
        this.loadAssessments();
      }
    })
  }

  requestSupport(assessment: any): void {
    this.dialog.open(RequestSupportComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: assessment,
      },
    }).afterClosed().subscribe((reload) => {
      this.titleService.setTitle("BAER - Assessments")
      if (reload) {
        this.loadAssessments();
      }
    })
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }
}