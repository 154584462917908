<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1>Request Interregional Support</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <form id="filter_form" class="filter_form" [formGroup]="fireForm">
            <div class="div-label-block">
                <h2>FIRE DETAILS</h2>
                <mat-label class="title">Fire/Complex name</mat-label>
                <mat-label>{{data.fireRecord.baer_name}}</mat-label>
                <mat-label class="title">Forest name</mat-label>
                <mat-label>{{data.fireRecord.forest_name | forest_filter}}</mat-label>
                <mat-label class="title">Region</mat-label>
                <mat-label>{{data.fireRecord.region}}</mat-label>
                <mat-label class="title">Complexity</mat-label>
                <mat-label class="chip-label" [ngSwitch]="data.fireRecord.fire_complexity_id">
                    <mat-chip *ngSwitchCase=1 class="chip-low">LOW</mat-chip>
                    <mat-chip *ngSwitchCase=2 class="chip-moderate">MODERATE</mat-chip>
                    <mat-chip *ngSwitchCase=0 class="chip-high">HIGH</mat-chip>
                    <div *ngSwitchDefault>--</div>
                </mat-label>
                <mat-label class="title">Assessment Status</mat-label>
                <mat-label class="chip-label" [ngSwitch]="data.fireRecord.assessment_status_id">
                    <mat-chip *ngSwitchCase=1 class="chip-light-grey">PRE-SIZE UP</mat-chip>
                    <mat-chip *ngSwitchCase=2 class="chip-light-orange">NEEDED</mat-chip>
                    <mat-chip *ngSwitchCase=3>NOT NEEDED</mat-chip>
                    <mat-chip *ngSwitchDefault>{{data.fireRecord.assessment_status_id || "N/A"}}</mat-chip>
                </mat-label>
                <mat-label class="title"></mat-label>
                <mat-label></mat-label>
            </div>
            <div class="whole-line">
                <div class="title">Select the type of support needed</div>
                <mat-radio-group required formControlName="support_type" class="radio-buttons">
                    <mat-radio-button [value]="'leader'" color="primary" class="radio-button">Need a team
                        leader</mat-radio-button>
                    <br>
                    <mat-radio-button [value]="'skills'" color="primary">Need team members with specific
                        skillset</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="whole-line" *ngIf="fireForm.value.support_type == 'skills'">
                <div class="title"><span class="red">*</span>Select skill(s) needed<span class="gray-text">(Select all that apply)</span></div>
                <div class="whole-line checkBoxGroup">
                    <div *ngFor="let skill of skillList">
                        <mat-checkbox (change)="updateSkill(skill)">
                            {{ skill.name }}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="right-align" *ngIf="skillList[skillList.length - 1].checked">
                    <div class="title"><span class="red">*</span>Other assigned skill</div>
                    <mat-form-field appearance="outline" class="medium-field">
                        <input matInput formControlName="other_skill" placeholder="Enter other skill" aria-label="Other assigned skill">
                    </mat-form-field>
                </div>
            </div>
            <div class="whole-line" *ngIf="fireForm.value.support_type != ''">
                <div class="title">Additional Details<span class="gray-text">(Optional)</span>
                </div>
                <mat-form-field class="text-area" appearance="outline">
                    <textarea matInput formControlName="details" placeholder="Enter description"></textarea>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button aria-label="Cancel" class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button aria-label="Update" class="usa-button" [disabled]="!fireForm.valid || !formValid"
                (click)="sumbitRequest()">Submit Request<i class="material-icons">chevron_right</i></button>
        </div>
    </div>
</div>