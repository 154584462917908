import { Component, Input, OnInit, SimpleChanges, input, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { AssessmentService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-reports-tab',
  templateUrl: './reports-tab.component.html',
  styleUrl: './reports-tab.component.scss'
})
export class ReportsTabComponent implements OnInit {


  edit_fire = signal<boolean>(false);
  @Input() fireObs?: Observable<any>;
  @Input() updateFireSubject?: Subject<any>;
  @Input() isCo_or!: boolean;
  @Input() isTeamLead!: boolean;
  editable = true;

  constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle("BAER - 2500-8 Reports")
  }

  ngOnInit(): void {

  }

  createInitialReport() {

  }
}
