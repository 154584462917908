import { Component, OnInit, SimpleChanges, input, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent implements OnInit {
  user_id = input.required<number>();
  edit_contact = signal<boolean>(false);
  edit_availability = signal<boolean>(false);
  edit_skills = signal<boolean>(false);
  refreshUser = new Subject<void>();
  user: any;
  currentUser: any;
  assignmentList: any;
  editable: boolean = false;
  selfEditable: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private userService: UserService,
    private titleService: Title
  ) {
    this.userService.getUserProfile().then((user) => {
      this.currentUser = user;
    })
  }

  ngOnInit(): void {
    this.userService.getUser(this.user_id()).subscribe((data) => {
      this.user = data.users[0];

      // 8 - Forest BAER Coordinator
      const is8 = this.currentUser.baer_roles.find((obj: any) => [8].includes(obj.value))

      if (this.user.user_id == this.currentUser.user_id || is8) {
        this.selfEditable = true;
        this.editable = true;
        this.titleService.setTitle("BAER - My Profile")
      }
      else {
        this.titleService.setTitle("BAER - " + data.users[0].first_name + ' ' + data.users[0].last_name)
      }
      // 3 - BAER Regional Coordinator
      // 4 - BAER National Coordinator
      // 5 - BAER Unit Coordinator
      // 7 - Washington Office Director
      // 8 - Forest BAER Coordinator
      // 9 - Forest BAR Coordinator
      // 11 - Forest Disaster Coordinator
      // 12 - RO BAER Coordinator
      // 13 - RO BAR Coordinator
      // 15 - RO Disaster Coordinator
      // 16 - WO Coordination
      // 17 - WO Logistics
      const iscoordinator = this.currentUser.baer_roles.find((obj: any) => [0, 3, 4, 5, 7, 8, 9, 11, 12, 13, 15, 16, 17].includes(obj.value))
      if (iscoordinator) this.editable = true;
      // 0-BAER Reporting Tool Administrator
      // 4-BAER National Coordinator
      // 7-Washington Office Director
      // 16-WO Coordination
      // 17-WO Logistics
      const isAdmin = this.currentUser.baer_roles.find((obj: any) => [0, 4, 7, 16, 17].includes(obj.value))
      if (isAdmin) this.isAdmin = true;
    })

    this.userService.getAssignmentListByUser(this.user_id()).subscribe((assignmentList) => {
      this.assignmentList = assignmentList;
    })

    // Refreshes user when .next() is called on refreshUser
    this.refreshUser.asObservable().subscribe(() => {
      this.userService.getUser(this.user_id()).subscribe((data) => {
        this.user = data.users[0];
      })
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }
}
