import { Component, Input, OnInit, input, model } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { EditFireComponent } from 'src/app/routes/fire-tracker/edit-fire/edit-fire.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-forest-contacts',
  templateUrl: './view-forest-contacts.component.html',
  styleUrl: './view-forest-contacts.component.scss'
})
export class ViewForestContactsComponent implements OnInit {

  faEdit = faEdit;
  edit_fire = model<boolean>();
  fire: any;
  @Input() fireObs?: Observable<any>;
  @Input() updateFireSubject?: Subject<any>;
  editable = input.required<boolean>();
  users: any[] = [];

  constructor(
    public dialog: MatDialog,
    private readonly userService: UserService,
  ) { }

  ngOnInit() {
    this.fireObs?.subscribe((fire: any) => {
      this.fire = fire;

      this.userService.getUserList().subscribe(data => {
        data.users.forEach((user: any) => {
          if (!user.baer_roles.find((obj: any) =>
            [8, 9].includes(obj.value))) return

          if (user.forest_name != this.fire.forest_name) return

          this.users.push(user)
          user.showRoles = []
          user.baer_roles.forEach((role: any) => {
            if ([8, 9].includes(role.value))
              user.showRoles.push(role.text)
          })
        })
      })
    })
  }

  edit() {
    this.dialog.open(EditFireComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: this.fire,
      },
    }).afterClosed().subscribe((reload: boolean) => {
      if (reload) {
        this.updateFireSubject?.next(null)
      }
    })
  }
}
