<header class="baer-header">
    <div class="breadcrumb"><a routerLink="/assessments">BAER Assessments</a> &gt; {{fire?.baer_name}}</div>
    <h1 id="table" class="site-page-title"><span class="large-font">{{fire?.baer_name}}</span></h1>
    <div class="status-div">
        BAER Assessment Status:
        <mat-chip [ngClass]="'chip-'+fire.assessment_status_id">
            {{fire?.assessment_status_id | statusBadge}}
        </mat-chip>
    </div>
</header>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" *ngIf="user">
    <mat-tab aria-hidden="false">
        <ng-template mat-tab-label>
            <fa-icon [icon]="faUsers" style="font-size: 20px;" aria-label="All Users"></fa-icon>&nbsp;
            2500-8 Reports
        </ng-template>
        <ng-template matTabContent>
            <app-reports-tab
                [fireObs]="fireObs"
                [updateFireSubject]="updateFireSubject" 
                [isCo_or]="isCo_or" 
                [isTeamLead]="isTeamLead && (fire?.team_lead?.user_id == user.user_id)"
            ></app-reports-tab>
        </ng-template>
    </mat-tab>
</mat-tab-group>