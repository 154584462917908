import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'app-edit-assessment',
  templateUrl: './edit-assessment.component.html',
  styleUrl: './edit-assessment.component.scss'
})
export class EditAssessmentComponent implements OnInit {

  private readonly isBrowser: boolean;

  currentUser: any;
  private readonly fireRecord: any = this.data.fireRecord;

  displayedColumns!: string[];

  team_needs: any[];
  leadList: any[] = [];

  fireForm = this.formBuilder.group({
    notes: [""],
    teamLeads: [null],
    team_needs_id: [-1],
    close_out_date: [null] as unknown as Date[],
    tentative_start_week: [null] as unknown as Date[],
    trainees_needed: [false],
  });

  constructor(
    public dialogRef: MatDialogRef<EditAssessmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly toast: ConfirmationToastComponent,
    private readonly assessmentService: AssessmentService,
    private readonly titleService: Title,
    private readonly userService: UserService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.titleService.setTitle("BAER - Edit Team Needs")
    dialogRef.disableClose = true;

    this.team_needs = this.assessmentService.getBAERTeamNeeds();

  }

  ngOnInit(): void {
    this.fireForm.patchValue({
      teamLeads: this.fireRecord.team_lead_id,
      notes: this.fireRecord.notes,
      team_needs_id: this.fireRecord.team_needs_id,
      close_out_date: this.fireRecord.close_out_date,
      tentative_start_week: this.fireRecord.tentative_start_week,
      trainees_needed: this.fireRecord.trainees_needed,
    })
    this.findAvalibleLeads(this.data.isTeamLead)
  }

  editAssessment(): void {
    if (typeof this.fireForm.value.close_out_date === 'string') {
      this.fireForm.value.close_out_date = new Date(this.fireForm.value.close_out_date)
    }
    if (typeof this.fireForm.value.tentative_start_week === 'string') {
      this.fireForm.value.tentative_start_week = new Date(this.fireForm.value.tentative_start_week)
    }
    let close_out_date: Date = this.fireForm.value.close_out_date as unknown as Date
    let tentative_start_week: Date = this.fireForm.value.tentative_start_week as unknown as Date
    let close_out_date_string = undefined;
    let tentative_start_week_string = undefined;
    if (close_out_date) {
      close_out_date_string = close_out_date.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
    }
    if (tentative_start_week) {
      tentative_start_week_string = tentative_start_week.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
    }

    this.assessmentService.editAssessment({
      assessment_id: this.fireRecord.assessment_id,
      notes: this.fireForm.value.notes as string | undefined,
      team_needs_id: this.fireForm.value.team_needs_id as number | undefined,
      close_out_date: close_out_date_string,
      tentative_start_week: tentative_start_week_string,
      trainees_needed: this.fireForm.value.trainees_needed as boolean | undefined,
      team_lead_id: this.fireForm.value.teamLeads as number | undefined
    }).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`${this.fireRecord.baer_name} assessment has been updated.`);
        this.dialogRef.close(true);
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error updating ${this.fireRecord.baer_name}.`, "Please try editing the assessment again.");
        this.dialogRef.close();
      },
    })
  }

  findAvalibleLeads(isTeamLead: boolean) {
    this.userService.getPossibleLeadList().subscribe((data: any) => {
      let users = data.users;
      if (!isTeamLead) {
        this.leadList = users;
      } else {
        this.leadList = []
        users.forEach((user: any) => {
          if (this.fireRecord.region == user.region && this.fireRecord.forest_name == user.forest_name) this.leadList.push(user)
        })
      }
      if (this.fireRecord.team_lead)
        this.leadList.push({ 'user_id': this.fireRecord.team_lead.user_id, 'first_name': this.fireRecord.team_lead.first_name, 'last_name': this.fireRecord.team_lead.last_name })
    });
  }
}
