import { AfterViewInit, Component, SimpleChanges, ViewChild, input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-view-assignments',
  templateUrl: './view-assignments.component.html',
  styleUrl: './view-assignments.component.scss'
})
export class ViewAssignmentsComponent implements AfterViewInit {
  assignmentList = input<any>();
  assignments = new MatTableDataSource<any>();
  displayedColumns!: string[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort;

  constructor() {
    this.displayedColumns = [
      'fireComplexName',
      'reportID',
      'forest_name',
      'skills'
    ]
  }

  ngOnChanges(changes: SimpleChanges) {
    this.assignments.data = this.assignmentList();
    this.setTooltip(this.assignments.data)
  }

  ngAfterViewInit() {
      this.assignments.sort = this.sort;
      this.assignments.paginator = this.paginator;

      // If the user changes the sort order, reset back to the first page.
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  setTooltip(assignments: any) {
    assignments.forEach((assignment: any) => {
      let tooltip = '';
      for (let i = 1; assignment.skill_list.length - 1 >= i; i++) {
        tooltip += assignment.skill_list[i].text + '\n'
      }
      assignment.tooltip = tooltip;
    });
  }

}
