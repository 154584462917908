<mat-card>
  <mat-card-header>
    <mat-card-title>BAER TEAM NEEDS</mat-card-title>
    <button class="usa-button usa-button--secondary" (click)="edit()" style="min-width: 70px;" *ngIf="editable()" aria-label="Edit Fire">
      <fa-icon [icon]="faEdit" style="color: #216E1F;"></fa-icon>&nbsp;Edit
    </button>
  </mat-card-header>
  <mat-card-content *ngIf="fire">
    <div class="data-div">
      <p class="data-title">BAER team needs</p>
      <p class="data-info">{{fire.team_needs.text || '--'}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">BAER team lead</p>
      <p class="data-info">{{fire.forest_name}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Are you willing to accept trainees?</p>
      <p class="data-info">{{fire.trainees_needed? "Yes" : "No"}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Tentative BAER Start week</p>
      <p class="data-info">{{(fire.tentative_start_week | date:"MM/dd/YYYY") || "--"}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Team Close-out Date</p>
      <p class="data-info">{{(fire.close_out_date | date:"MM/dd/YYYY") || "--"}}</p>
    </div>
    <div class="data-div-large">
      <p class="data-title">Notes</p>
      <p class="data-info">{{fire.notes || '--'}}</p>
    </div>
  </mat-card-content>
</mat-card>