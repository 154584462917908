import { Component, Input, OnInit, input, model } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { faEdit, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { EditFireComponent } from 'src/app/routes/fire-tracker/edit-fire/edit-fire.component';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-view-reports',
  templateUrl: './view-reports.component.html',
  styleUrl: './view-reports.component.scss'
})
export class ViewReportsComponent implements OnInit {

  faEdit = faEdit;
  faFileLines = faFileLines;
  reports = new MatTableDataSource<any>();
  displayedColumns: string[] = ['name', 'reportId','action_type','report_type','funding_requested','authorized_funding','sumbission_date','reportStatus','action'];

  edit_fire = model<boolean>();
  fire: any;
  @Input() fireObs?: Observable<any>;
  @Input() updateFireSubject?: Subject<any>;
  @Input() isCo_or!: boolean;
  @Input() isTeamLead!: boolean;
  editable = input.required<boolean>();

  constructor(
    public dialog: MatDialog,
    private readonly reportService: ReportService,
  ) { }

  ngOnInit() {
    this.fireObs?.subscribe((fire: any) => {
      if (fire != this.fire) {
        this.reportService.getReportList(fire.assessment_id).subscribe(
          {
            next: (data) => {
              data = []; // Temp until report data is added
              this.setTooltip(data);
              this.reports.data = data;
            },
            complete: () => {}
          });
      }
      this.fire = fire;
    })
  }

  edit() {
    this.dialog.open(EditFireComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: this.fire,
      },
    }).afterClosed().subscribe((reload: boolean) => {
      if (reload) {
        this.updateFireSubject?.next(null)
      }
    })
  }

  setTooltip(reports: any) {
    reports.forEach((report: any) => {
      let tooltip = 'Team Lead(s)\n';
      for (let member of report.team_members.filter((member: any) => member.role_id === 1)) {
        tooltip += member.first_name + ' ' + member.last_name + '\n'
      }
      tooltip += '\nTeam Member(s)\n'
      for (let member of report.team_members.filter((member: any) => member.role_id === 2)) {
        tooltip += member.first_name + ' ' + member.last_name + '\n'
      }
      report.tooltip = tooltip;
    });
  }
}
