<h3 class="report-count">{{ reports?.data?.length || "0"}} Reports</h3>
<table mat-table [dataSource]="reports" class="report-table">
  <ng-container matColumnDef="filler">
    <th mat-header-cell *matHeaderCellDef class="filler-column" style="width: 48px;"></th>
    <td mat-cell *matCellDef="let report" class="filler-column"></td>
  </ng-container>
  <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Report name</th>
      <td mat-cell *matCellDef="let report">{{fire?.baer_name}} ({{report.report_num}})</td>
  </ng-container>
  <ng-container matColumnDef="reportId">
      <th mat-header-cell *matHeaderCellDef>Report ID</th>
      <td mat-cell *matCellDef="let report" class="prewrap">{{report.reportId}}</td>
  </ng-container>
  <ng-container matColumnDef="action_type">
      <th mat-header-cell *matHeaderCellDef>Action type</th>
      <td mat-cell *matCellDef="let report" class="prewrap">{{report.action_type}}</td>
  </ng-container>
  <ng-container matColumnDef="report_type">
      <th mat-header-cell *matHeaderCellDef>Report type</th>
      <td mat-cell *matCellDef="let report" class="prewrap">{{report.report_type}}</td>
  </ng-container>
  <ng-container matColumnDef="funding_requested">
      <th mat-header-cell *matHeaderCellDef>Funding requested</th>
      <td mat-cell *matCellDef="let report" class="prewrap">{{report.funding_requested}}</td>
  </ng-container>
  <ng-container matColumnDef="authorized_funding">
      <th mat-header-cell *matHeaderCellDef>Authorized Funding</th>
      <td mat-cell *matCellDef="let report" class="prewrap">{{report.authorized_funding}}</td>
  </ng-container>
  <ng-container matColumnDef="sumbission_date">
    <th mat-header-cell *matHeaderCellDef>Sumbission Date</th>
    <td mat-cell *matCellDef="let report" class="prewrap">{{report.sumbission_date}}</td>
</ng-container>
  <ng-container matColumnDef="reportStatus">
      <th mat-header-cell *matHeaderCellDef>Report Status</th>
      <td mat-cell *matCellDef="let report">
          <mat-chip [ngClass]="'chip-'+report.report_status_id">
              {{report.report_status_id | statusBadge}}
          </mat-chip>
      </td>
  </ng-container>
  <ng-container matColumnDef="authorization_status">
    <th mat-header-cell *matHeaderCellDef>Authorization Status</th>
    <td mat-cell *matCellDef="let report" class="prewrap">{{report.authorization_status}}</td>
  </ng-container>
  <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="width: 270px;">
          <div class="info-containment-div">Actions</div>
      </th>
      <td mat-cell *matCellDef="let report" style="width: 270px;">
          <button class="edit-btn" *ngIf="isTeamLead || isCo_or"><fa-icon class="fa-sm" [icon]="faEdit"></fa-icon>Edit Report</button>
      </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9">
          <div class="no-data">
            <div class="no-data-icon">
                <fa-icon [icon]="faFileLines" class="no-data-icon3"></fa-icon>
            </div>
            <div class="no-data-title">No Reports Have Been Created</div>
        </div>
      </td>
  </tr>
</table>