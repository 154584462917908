<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1>Edit BAER Team Needs</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <form id="filter_form" class="filter_form" [formGroup]="fireForm">
            <div class="div-label-block">
                <h2>FIRE DETAILS</h2>
                <mat-label class="title">Fire/Complex name</mat-label>
                <mat-label>{{data.fireRecord.baer_name}}</mat-label>
                <mat-label class="title">Forest name</mat-label>
                <mat-label>{{data.fireRecord.forest_name | forest_filter}}</mat-label>
                <mat-label class="title">Region</mat-label>
                <mat-label>{{data.fireRecord.region}}</mat-label>
                <mat-label class="title">Complexity</mat-label>
                <mat-label class="chip-label" [ngSwitch]="data.fireRecord.fire_complexity_id">
                    <mat-chip *ngSwitchCase=1 class="chip-low">LOW</mat-chip>
                    <mat-chip *ngSwitchCase=2 class="chip-moderate">MODERATE</mat-chip>
                    <mat-chip *ngSwitchCase=0 class="chip-high">HIGH</mat-chip>
                    <div *ngSwitchDefault>--</div>
                </mat-label>
                <mat-label class="title">Assessment Status</mat-label>
                <mat-label class="chip-label">
                    <mat-chip [ngClass]="'chip-'+data.fireRecord.assessment_status_id">
                        {{data.fireRecord.assessment_status_id | statusBadge}}
                    </mat-chip>
                </mat-label>
                <mat-label class="title"></mat-label>
                <mat-label></mat-label>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>BAER Team Needs</mat-label>
                <mat-select formControlName="team_needs_id">
                    @for (need of team_needs; track need) {
                    <mat-option [value]="need.value">{{need.text}}</mat-option>
                    }
                </mat-select>
                <mat-icon
                    matTooltip="Prioritize local coverage for your team, then regional. If interregional support is needed, submit an interregional support request from the fire's action menu."
                    matTooltipClass="tooltipClass" class="info-blue-field fa-regular fa-circle-info"
                    aria-label="Info">info_outline</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>BAER Team Lead</mat-label>
                <mat-select formControlName="teamLeads">
                    @for (user of leadList; track user) {
                    <mat-option [value]="user.user_id">{{ user.first_name + " " + user.last_name }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <div class="whole-line">
                <div class="title">Are you willing to accept trainees?</div>
                <mat-radio-group required formControlName="trainees_needed" class="radio-buttons">
                    <mat-radio-button [value]="true" color="primary" class="radio-button">Yes</mat-radio-button>
                    <mat-radio-button [value]="false" color="primary">No</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="whole-line">
                <div class="title">Notes<span class="gray-text">(Concerns, highlights, staffing needs)</span>
                </div>
                <mat-form-field class="text-area" appearance="outline">
                    <textarea matInput formControlName="notes" placeholder="Enter description"></textarea>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Tentative BAER Start Week</mat-label>
                <input matInput formControlName="tentative_start_week" [matDatepicker]="picker1" placeholder="MM/DD/YYYY"
                    aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Team Close-out Date</mat-label>
                <input matInput formControlName="close_out_date" [matDatepicker]="picker2" placeholder="MM/DD/YYYY"
                    aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <div class="whole_width row">
                <div>
                    If additional team leads or member(s) support will be required outside the region, select <span
                        class="green-text">Interregional Support</span> from the fire's actions menu.
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button aria-label="Cancel" class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button aria-label="Update" class="usa-button" [disabled]="!fireForm.valid"
                (click)="editAssessment()">Save</button>
        </div>
    </div>
</div>