import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly userProfile$: Promise<any> = this.setUser();
  // Static list of skill references (sorted in order to display)
  // Could be call to database, but static for now
  skillsList = [
    { text: "Team Leader", value: 15 },
    { text: "Assistant Team Leader", value: 16 },
    { text: "Logistics Coordinator", value: 17 },
    { text: "Liaison Officer", value: 18 },
    { text: "Archaeologist", value: 0 },
    { text: "Engineer", value: 1 },
    { text: "Fisheries", value: 2 },
    { text: "Geologist", value: 3 },
    { text: "GIS Specialist", value: 4 },
    { text: "Hydrologist", value: 19 },
    { text: "Interagency Coordination", value: 5 },
    { text: "Invasive Species Botany", value: 6 },
    { text: "Public Information Officer", value: 7 },
    { text: "Recreation Specialist", value: 8 },
    { text: "Safety Officer", value: 9 },
    { text: "Soil Scientist", value: 10 },
    { text: "Wildlife Biologist", value: 11 },
  ]
  skillNames: { [id: string]: any } = {}

  skillIdMap = new Map<number, string>();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    ) {
    this.skillsList.forEach((item) => {
      this.skillNames[item.text] = item.value
    })
    for (let skill in this.skillNames) {
      this.skillIdMap.set(this.skillNames[skill], skill)
    }
  }

  getUserProfile(): Promise<any> {
    return this.userProfile$;
  }

  refreshUserProfile(): Promise<any> {
    return this.setUser();
  }

  private async setUser() {
    try {
      return await firstValueFrom(this.getLoginUser());
    }
    catch {
      console.log('no user returned. signing out');
      this.authService.logout();
    }
  }
  /**
   * GET request for user data
   *
   * @returns Promise of list of users
   */
  getUserList(type?: string): Observable<any> {
    let params = new HttpParams()
    let queryParams = ""
    if (type) {
      queryParams = this.checkForBlank(queryParams);
      queryParams += "roster_type=" + type
    }
    if (queryParams != "") {
      queryParams = "?" + queryParams
    }
    return this.httpClient.get(
      `${environment.apiUrl}/user${queryParams}`, { params: params }
    );
  }
  /**
   * GET request for user data
   *
   * @returns Promise of list of all active Leads
   */
  getPossibleLeadList(): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.get(
      `${environment.apiUrl}/user?assignedToAssessment=false`, { params: params }
    );
  }
  /**
   * GET request for user data
   *
   * @returns Promise of list of all active Leads
   */
  getActiveLeadList(): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.get(
      `${environment.apiUrl}/user?assignedToAssessment=true`, { params: params }
    );
  }

  checkForBlank(queryParams: string) {
    if (queryParams != "") queryParams += "&"
    return queryParams
  }

  getUsersByEmail(email: string): Observable<any> {
    let params = new HttpParams()
    let queryParams = "?email=" + email
    queryParams = queryParams.replaceAll("@", "%40")
    return this.httpClient.get(
      `${environment.apiUrl}/user${queryParams}`, { params: params }
    );
  }

  getUser(user_id: number): Observable<any> {
    let params = new HttpParams()
    let queryParams = "?user_id=" + user_id
    queryParams = queryParams.replaceAll("@", "%40")
    return this.httpClient.get(
      `${environment.apiUrl}/user${queryParams}`, { params: params }
    );
  }

  getLoginUser(): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.get(
      `${environment.apiUrl}/auth/user`, { params: params }
    );
  }

  /**
   * Sends POST request to create new user
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
  createNewUser(data: any): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.post(
      `${environment.apiUrl}/user`, data, { params: params }
    );
  }

  /**
   * Sends DELETE request to edit user specified by user_id
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
  deleteUser(data: {
    user_id_list: number[]
  }): Observable<any> {
    return this.httpClient.delete(
      `${environment.apiUrl}/user`, {
      body: data
    }
    );
  }

  /**
   * Sends PUT request to edit user specified by user_id
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
  editUserDetails(data: any): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.put(
      `${environment.apiUrl}/user`, data, { params: params }
    );
  }

  editUserAvailability(data: {
    //TODO
  }): Observable<any> {
    return of(new Observable);
  }

  editUserSkills(data: {
    //TODO
  }): Observable<any> {
    return of(new Observable);
  }

  getAssignmentListByUser(uuid: number): Observable<any> {
    //TODO
    let assignments = null;
    return of(assignments)
  }

  getAgencyList(): Array<object> {
    return [
      { text: "USFS", value: 0 },
      { text: "BLM", value: 1 },
      { text: "FWS", value: 2 },
      { text: "USGS", value: 3 },
      { text: "NWS", value: 4 },
      { text: "NPS", value: 5 },
      { text: "NRCS", value: 6 },
      { text: "Not Applicable (AD)", value: 7 }
    ];
  }

  getExpList(): Array<object> {
    return [
      { text: "Trainee", value: 0 },
      { text: "1 to 5 assignments", value: 1 },
      { text: "6 to 10 assignments", value: 2 },
      { text: "10+ assignments", value: 3 },
    ];
  }

  getSkillList(): Observable<Array<object>> {
    return of(this.skillsList);
  }

  /**
   * Returns Map of skill_id to skill text
   *
   * @returns number to string map
   */
  getSkillIdMap(): Map<number, string> {
    return this.skillIdMap
  }

  /**
   * Returns dictionary of skills, indexed by name
   *
   * @returns dictionary of skills with string indexes
   */
  getSkillNames(): { [id: string]: any } {
    return this.skillNames
  }

  getRoleList(): Observable<Array<object>> {
    // TODO - get full list from api
    return of([
      { value: 0, text: "BAER Reporting Tool Administrator", type: "BAER" },
      { value: 1, text: "BAER Team Leader", type: "BAER" },
      { value: 2, text: "BAER Team Member", type: "BAER" },
      // { value: 3, text: "BAER Regional Coordinator", type: "ROCO" },
      // { value: 4, text: "BAER National Coordinator", type: "WOCO" },
      // { value: 5, text: "BAER Unit Coordinator", type: "FOCO" },
      // { value: 6, text: "Forest Unit Line Officer, Regional Line Officer", type: "BAER" },
      { value: 7, text: "Washington Office Director", type: "BAER" },
      { value: 8, text: "Forest Unit BAER Coordinator", type: "FOCO" },
      { value: 9, text: "Forest BAR Coordinator", type: "FOCO" },
      { value: 10, text: "Forest READ Coordinator", type: "FOCO" },
      { value: 11, text: "Forest Disaster Coordinator", type: "FOCO" },
      { value: 12, text: "RO BAER Coordinator", type: "ROCO" },
      { value: 13, text: "RO BAR Coordinator", type: "ROCO" },
      { value: 14, text: "RO READ Coordinator", type: "ROCO" },
      { value: 15, text: "RO Disaster Coordinator", type: "ROCO" },
      { value: 16, text: "WO Coordination", type: "WOCO" },
      { value: 17, text: "WO Logistics", type: "WOCO" },
      { value: 18, text: "Forest Unit Line Officer", type: "FOLO" },
      { value: 19, text: "Acting Forest Unit Line Officer", type: "FOLO" },
      { value: 20, text: "Regional Line Officer", type: "ROLO" },
      { value: 21, text: "Acting Regional Line Officer", type: "ROLO" },
      { value: 22, text: "Washington Office Line Officer", type: "WOLO" },
      { value: 23, text: "Acting Washington Office Line Officer", type: "WOLO" }
    ])
  }

  checkAgencyRegionForest(agency: any, region: any, forest: any): boolean {
    if (agency == 0) { //user is USFS
      if (region == 'WO') return true; //WO no forest
      if (region != '') { //region selected
        if (forest != '') return true; //forest selected
      }
    }
    else if (agency != '') { //agency is selected - not USFS
      return true;
    }
    return false; //did not hit valid value combination
  }
}
