<main id="main-content" class="main-content">
    <div class="card top-card" style="display: flex;">
        <div class="sub-title-div">
            <h2>2500-8 Reports</h2>
        </div>
        <div class="button-row">
            <button class="usa-button long-button" (click)="createInitialReport()"><mat-icon>add</mat-icon>
                Create Initial report</button>
        </div>
    </div>
    <div class="card">
        <app-view-reports
        [(edit_fire)]="edit_fire" 
        [fireObs]="fireObs" 
        [updateFireSubject]="updateFireSubject" 
        [editable]="editable"
        [isCo_or]="isCo_or" 
        [isTeamLead]="isTeamLead"
        ></app-view-reports>
    </div>
    <div class="card">
        <app-view-fire 
        [(edit_fire)]="edit_fire" 
        [fireObs]="fireObs" 
        [updateFireSubject]="updateFireSubject" 
        [editable]="editable"
        ></app-view-fire>
    </div>
    <div class="card">
        <app-view-team-needs 
        [(edit_fire)]="edit_fire" 
        [fireObs]="fireObs" 
        [updateFireSubject]="updateFireSubject" 
        [editable]="editable"
        [isTeamLead]="isTeamLead"
        ></app-view-team-needs>
    </div>
    <div class="card">
        <app-view-forest-contacts
        [(edit_fire)]="edit_fire" 
        [fireObs]="fireObs" 
        [updateFireSubject]="updateFireSubject" 
        [editable]="editable"
        ></app-view-forest-contacts>
    </div>
</main>